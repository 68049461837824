@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');

.studio{
    padding:2% 5% 0 5%;
    height: 95vh;
}

.studio-actions{
    display: flex;
    justify-content: space-around;
    padding-bottom: 2%;
}

.textField{
    width: 70%
}

.studio-content{
    height: 90%;
}

.action-button{
    margin-left: 5% !important;
    margin-bottom: 0 !important;
    width: 20%;
    height: 100%;
    font-size: 1.5rem !important;
}

.view-div{
    display: flex;
    height: 100%;
    border: 1px solid lightgrey;
    border-radius: 5%;

}

.options-div{
    width: 50%;
    border-right: 1px solid #bcb8b8;
}

.image-div{
    border: 1px solid lightgrey;
    border-radius: 5%;
    margin: 3%;
    width: 50%;
    display: flex;
    padding: 2%;
    justify-content: center;
    
}

.image{
    display: contents;
}

.img{
    width: 100%;
    
    
    object-fit: scale-down;
}

.option-div{
    text-align: left;
    margin-top: 5%;
    padding: 2%;
}

.optionsHeading{
    font-family: 'Sora', sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 5px;
}

.box{
    border: 1px solid #9a9a9a;
    padding: 2px;
    border-radius: 10%;
    background: #f0ebeb;
}

.centeredDiv{
    width: inherit;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.modal-button{
  position: absolute !important;
    bottom: 5%;
    margin: 0 !important;
    left: 45%;
}