@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

.logo-div{
    margin-top: 10%;
}

.logo-image{
    width: 10%;
    
}

.logo-text{
    color: #ed1b64;
    font-family: 'Sora', sans-serif;
    font-size: 2.125rem;
    font-weight: 600;
}

.buttons-div{
    display: flex;
    width: max-content;
    flex-direction: column;
    margin: auto;
}

@media only screen and (max-width: 700px){
    .logo-div{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 20%;
    }
}