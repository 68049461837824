.freeform{
    margin: 5% auto;
}

.content{
    width: 34%;
    margin: auto;
}

.imageDiv{
    margin: 5% auto;
    width: 100%;
    height: 100%;
}

.image {
    width: 100%;
    height: 100%;
}

.buttonDiv{
    margin-top: 5%;
}

@media only screen and (max-width: 700px){
    .content{
        width: 90%;
        margin: auto;
    }
    .freeform{
        margin: 15% auto;
    }
}