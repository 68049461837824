.character-inputs{
    margin: 5% auto;
    width: fit-content;
}

.heading{
    color: #ed1b64;
    font-family: 'Sora', sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 5%;
}

.inputBox{
    margin-bottom: 5% !important;
}

.buttons-div{
    display: flex;
    width: max-content;
    flex-direction: column;
    margin: auto;
}

.imageDiv{
    margin: 5% auto;
    width: 100% !important;
    height: 100% !important;
}

.image {
    width: 500px;
    height: 500px;
}

.content1{
    
    margin: auto;
}

.edit-div{
    margin-top: 5%;
}

@media only screen and (max-width: 700px){
    .content1{
        width: 90%;
        margin: auto;
    }
    .freeform{
        margin: 15% auto;
    }
    .image{
        
        width: 100% !important;
        height: 100% !important;
    }
}